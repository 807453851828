import React from 'react';

const DetailsSection = ({ savingsText }) => {
  return (
    <div className="py-[80px]">
      <div className="container mx-auto flex justify-around items-center text-themeColor">
        <div className="text-center px-4 md:px-0">
          <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold">5K+</h2>
          <p className="text-lg md:text-xl lg:text-xl">Satisfied Clients</p>
          {savingsText && <p className="text-sm mt-2">{savingsText}</p>}
        </div>
        <div className="text-center px-4 md:px-0">
          <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold">5 stars</h2>
          <p className="text-lg md:text-xl lg:text-xl">Rated for customer service</p>
        </div>
        <div className="text-center px-4 md:px-0">
          <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold">100%</h2>
          <p className="text-lg md:text-xl lg:text-xl">Claims support</p>
        </div>
      </div>
    </div>
  );
};

export default DetailsSection;
