import React from 'react'
import { TbBulb } from "react-icons/tb";

const TailoredSolutions = () => {
	return (
		<div className="w-full mt-[40px]">
			<p className='ml-auto w-[95%] md:w-[80%] lg:w-[80%] md:w-[50%] lg:w-[50%] rounded-l-full bg-themeColor py-8 text-white text-[28px] md:text-[32px] lg:text-[32px] text-center font-bold flex items-center justify-start'><TbBulb className='ml-8 mx-4' size={40}/>Tailored Solutions</p>
			<p className='text-themeColor p-[50px] text-[22px] font-medium text-right ml-auto w-[95%] md:w-[80%] lg:w-[80%]'>Personalized insurance plans designed just for you. We understand your concerns and provide the right coverage at the right time.</p>
		</div>
	)
}

export default TailoredSolutions