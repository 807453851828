import React from 'react';
import AndyImage from '../assets/andy-cortez.jpg'; // Path to Andy's image
import MarilynnImage from '../assets/marilynn-flores.jpg'; // Path to Marilynn's image

const AboutUs = () => {
  return (
    <div className="p-8 max-w-[90%] w-[90%] mx-auto my-10">
      <div className="container mx-auto py-10 px-4">
        
        {/* Section for Andy */}
        <div className="mb-10">
          <img src={AndyImage} alt="Andy Cortez" className="rounded-full mx-auto w-[200px] h-[200px] object-cover"/>
          <h2 className="text-3xl text-themeColor font-bold mt-4">Andy Cortez</h2>
          <p className="mt-4">
            Welcome to the Andy Cortez Insurance Agency, where California Dreamin' meets a Safety Net!
            I'm Andy Cortez, founder of this agency, and I believe in more than just insurance – I believe in understanding people.
            With a passion for insuring with care and a knack for making the complexities of insurance understandable,
            I'm here to ensure you have the coverage you need, tailored just for you.
          </p>
          <p className="mt-4">
            I'm not just your insurance guy; I'm a fun-loving individual who enjoys bringing a smile to people's faces.
            After years in retail management, I chose to make a positive impact on lives through insurance.
            Whether it's protecting your assets or planning for your future, I'm dedicated to making your insurance experience easy and enjoyable.
          </p>
          <p className="mt-4">
            <b>Fun Fact about Andy:</b> I'm a huge sports fan and love being involved with local youth sports. You'll even find me coaching my own kids teams.
          </p>
          <p className="mt-4">
            So, if you're in the Santa Maria Valley or surrounding areas, stop by our office in the beautiful El Potrero Office Park.
            Let's chat about your insurance needs and how we can make sure you're covered with a smile!
          </p>
          <p className="mt-4">
            Give me a call at <a className="font-bold" href="tel:+18059341300">(805) 934-1300</a>, and let's get started!
          </p>
          <p className="mt-4 text-lg w-fit text-themeColor rounded">
            <b>CA License #:</b> <span class="underline">4193379</span>
          </p>
        </div>
        
        {/* Divider */}
        <hr className="my-10"/>

        {/* Section for Marilynn */}
        <div className="mb-10">
          <img src={MarilynnImage} alt="Marylinn Lopez" className="rounded-full mx-auto w-[200px] h-[200px] object-cover object-top"/>
          <h2 className="text-3xl text-themeColor font-bold mt-4">Marylinn Lopez</h2>
          <p className="mt-4">
            Hello there! I'm Marylinn Lopez, your personal lines executive at the Andy Cortez Insurance Agency.
            My passion for insurance is matched only by my love for traditional Spanish dancing and being a devoted mother.
          </p>
          <p className="mt-4">
            With years of experience and a deep commitment to helping our clients, I'm here to ensure you get the coverage that not only protects but also fits your unique needs.
            From auto and home to renters, business, and life insurance, I'll guide you through the process with expertise and care.
          </p>
          <p className="mt-4">
            <b>Fun Fact about Marylinn:</b> One of my passions is performing traditional Spanish dance at local cultural festivals.
            It's such a fulfilling experience to share my love for dance with others and be part of these vibrant celebrations.
          </p>
          <p className="mt-4">
            Visit us at our office in Santa Maria, and let's discuss how we can secure your future together!
          </p>
          <p className="mt-4">
            Give me a call at <a className="font-bold" href="tel:+18059341300">(805) 934-1300</a>, and let's make insurance a delightful experience for you!
          </p>
          <p className="mt-4 text-lg w-fit text-themeColor rounded">
            <b>CA License #:</b> <span class="underline">4236142</span>
          </p>
        </div>

      </div>
    </div>
  );
}

export default AboutUs;
