import React from 'react'
import { GiReceiveMoney } from "react-icons/gi";


const CostEffectiveProtection = () => {
	return (
		<div className="w-full mt-[40px]">
			<p className='mr-auto w-[95%] md:w-[80%] lg:w-[80%] md:w-[50%] lg:w-[50%] rounded-r-full bg-themeColor py-8 text-white text-[28px] md:text-[32px] lg:text-[32px] text-center font-bold flex items-center justify-end'>Cost-Effective Protection <GiReceiveMoney className='mr-8 mx-4' size={40}/></p>
			<p className='text-themeColor p-[50px] text-[22px] font-medium text-left mr-auto w-[95%] md:w-[80%] lg:w-[80%]'>We offer cost-effective insurance plans, ensuring you get maximum coverage without breaking the bank.</p>
		</div>
	)
}

export default CostEffectiveProtection