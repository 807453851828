import React, { useState } from 'react';
import { MdPhoneInTalk } from 'react-icons/md';

const HeroSection = () => {

  const [selectedInsurance, setSelectedInsurance] = useState('default')

	const handleRedirectToInsurancePage = () => {
		if(selectedInsurance !== 'default') {
			window.location = `/${selectedInsurance}`
		}
	}

  return (
    <div className="relative bg-blue-100 sm-p-0 p-[50px] min-h-[500px] flex items-center justify-center text-themeColor">
      {/* Hero Content */}
      <div className="z-10 flex flex-col lg:flex-row justify-center container mx-auto">
        {/* Left Side - Callout */}
        <div className="font-bold pr-12 sm:block max-w-[500px] mb-8 sm:mb-0">
          <p className='text-[42px]'>Welcome to ACIAI</p>
          <h1 className="text-[20px] mt-4 font-bold leading-tight text-wrap">Empowering Financial Security, Enhancing Life.</h1>
          <h1 className="text-[20px] mt-4 font-bold leading-tight text-wrap">Compassionate and effective insurance solutions for your peace of mind.</h1>
        </div>
        {/* Right Side - Form */}
        <div className="mt-8 lg:mt-0 max-w-md bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-2 text-gray-800">Let's Get Started</h2>
          <h1 className="text-4xl font-bold mb-6 text-themeColor">Get a Free Quote!</h1>
          <p className="mb-6 text-gray-600">Includes personal service from one of our agents!</p>
          <select className="mb-4 p-2 w-full rounded-md cursor-pointer appearance-none px-4 py-2 bg-blue-50" onChange={(e) => setSelectedInsurance(e.target.value)}>
            <option value='default'>Select</option>
            <option value='auto-insurance'>Auto Insurance</option>
            <option value='homeowners-insurance'>Homeowners Insurance</option>
            <option value='commercial-insurance'>Commercial Insurance</option>
            <option value='life-insurance'>Life Insurance</option>
          </select>
          <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-md mb-4 w-full" onClick={handleRedirectToInsurancePage}>
						Get Protected
          </button>
          <div className="text-center mt-4">
            <span>Or call us!</span>
            <a href="tel:+18059341300" className="flex items-center justify-center mt-1">
              <MdPhoneInTalk className="text-2xl mr-2" />
              <span className="font-bold text-lg">(805) 934-1300</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
