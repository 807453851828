import React from 'react'
import { MdLockOutline } from "react-icons/md";

const SecureYourFuture = () => {
	return (
		<div className="w-full mt-[40px]">
			<p className='ml-auto w-[95%] md:w-[80%] lg:w-[80%] md:w-[50%] lg:w-[50%] rounded-l-full bg-themeColor py-8 text-white text-[28px] md:text-[32px] lg:text-[32px] text-center font-bold flex items-center justify-start'><MdLockOutline className='ml-8 mx-4' size={40}/>Secure Your Future</p>
			<p className='text-themeColor p-[50px] text-[22px] font-medium text-right ml-auto w-[95%] md:w-[80%] lg:w-[80%]'>Your security is our priority. ACIAI safeguards your assets and loved ones, giving you the freedom to focus on what matters most.</p>
		</div>
	)
}

export default SecureYourFuture