import React from 'react'
import { FaQuestion } from "react-icons/fa";

const WhyChooseUs = () => {
	return (
		<div className="w-full">
			<p className='w-[95%] md:w-[80%] lg:w-[80%] md:w-[50%] lg:w-[50%] rounded-r-full bg-themeColor py-8 text-white text-[28px] md:text-[32px] lg:text-[32px] text-center font-bold flex items-center justify-end'>Why Choose Us <FaQuestion className='mr-8 mx-4' size={40}/></p>
			<p className='text-themeColor p-[50px] text-[22px] font-medium mr-auto w-[95%] md:w-[80%] lg:w-[80%]'>At ACIAI, we blend expertise, care, and dedication, tailoring insurance solutions to meet your unique needs and priorities.</p>
		</div>
	)
}

export default WhyChooseUs