import axios from 'axios';
import React, { useState } from 'react'
import { useLocation } from 'wouter';

const AutoInsurance = () => {
	
  const [formData, setFormData] = useState({
    yearOfVehicle: '',
    modelOfVehicle: '',
    makeOfVehicle: '',
    desiredCoverage: '',
    fullName: '',
    birthday: '',
    gender: '',
    driversLicense: '',
    streetAddress: '',
    email: '',
    city: '',
    phone: '',
    state: '',
    reasonForShopping: '',
    zip: '',
    comments: '',
  });

  // Handler for input changes to update formData state
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

	const [location, navigate] = useLocation()


	const submitForm = async () => {
		const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/45866956/016c3c33-2645-41ba-9388-e3c05b0cfb24`
	 
			 var name = formData.fullName.split(' ')
			 const firstName = name[0] || ''
			 const lastName = name[1] || ''
			 
			 const hubspotFormData = {
				fields: [
					{
						name: "email",
						value: formData.email,
					},
					{
						name: "firstname",
						value: firstName,
					},
					{
						name: "lastname",
						value: lastName,
					},
					{
						name: "year_of_vehicle",
						value: formData.yearOfVehicle,
					},
					{
						name: "model_of_vehicle",
						value: formData.modelOfVehicle,
					},
					{
						name: "make_of_vehicle",
						value: formData.makeOfVehicle,
					},
					{
						name: "desired_coverage",
						value: formData.desiredCoverage,
					},
					{
						name: "description",
						value: formData.moreCars || 'N/A',
					},
					{
						name: "more_driver",
						value: formData.moreDrivers || 'N/A',
					},
					{
						name: "date_of_birth",
						value: formData.birthday,
					},
					{
						name: "gender",
						value: formData.gender,
					},
					{
						name: "drivers_license_number",
						value: formData.driversLicense,
					},
					{
						name: "address",
						value: formData.streetAddress,
					},
					{
						name: "phone",
						value: formData.phone,
					},
					{
						name: "city",
						value: formData.city,
					},
					{
						name: "state",
						value: formData.state,
					},
					{
						name: "zip",
						value: formData.zip,
					},
					{
						name: "reason_for_shopping",
						value: formData.reasonForShopping,
					},
					{
						name: "message",
						value: formData.comments,
					},
				]
			 }
	 
			 try {
				 await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)
				 .then(async() => {
					//  setLoading(false)
	 
					 navigate('/')
				 })
			 } catch (error) {
					 console.error('Network error:', error)
					//  setLoading(false)
			 }
		 }

  // Handler for form submission
const handleSubmit = (event) => {
	event.preventDefault();
	submitForm()
};

const handleAddMoreCars = () => {}

const handleAddMoreDrivers = () => {}

return (
	<form onSubmit={handleSubmit} className="p-8 rounded-md shadow-sm max-w-[80%] w-[80%] mx-auto my-10 border border-gray-100">
		<div className='py-[40px] px-[20px] border-t-[1px] border-b-[1px] border-dashed border-themeColor'>
			<p className='text-[32px] font-bold text-themeColor'>Auto Insurance</p>
			<p className='text-[22px] font-medium text-gray-500'>Get your Free Auto Insurance Quote Today!</p>
		</div>

		<div className='mt-8'>
			<p className='text-[22px] font-bold mb-4 text-black'>Vehicle Information</p>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="yearOfVehicle">Year of Vehicle</label>
					<input className="themed-input" id="yearOfVehicle" name="yearOfVehicle" value={formData.yearOfVehicle} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="modelOfVehicle">Model of Vehicle</label>
					<input className="themed-input" id="modelOfVehicle" name="modelOfVehicle" value={formData.modelOfVehicle} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="makeOfVehicle">Make of Vehicle</label>
					<input className="themed-input" id="makeOfVehicle" name="makeOfVehicle" value={formData.makeOfVehicle} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="desiredCoverage">Desired Coverage</label>
					<select className="themed-input cursor-pointer" id="desiredCoverage" name="desiredCoverage" value={formData.desiredCoverage} onChange={handleInputChange} required>
						<option value="">--Please choose an option--</option>
						<option value="Liability Only">Liability Only</option>
						<option value="Liability &amp; Uninsured Motorist">Liability &amp; Uninsured Motorist</option>
						<option value="Full Coverage">Full Coverage</option>
						<option value="Full Coverage &amp; Uninsured Motorist">Full Coverage &amp; Uninsured Motorist</option>
						<option value="Not Sure">Not Sure</option>
					</select>
				</div>
			</div>

			<p className="mt-8 text-[18px] font-bold underline text-themeColor cursor-pointer" onClick={handleAddMoreCars}>Add more cars?</p>
		</div>

		<div className='mt-8'>
			<p className='text-[22px] font-bold mb-4'>Driver Information</p>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="fullName">Full Name</label>
					<input className="themed-input" id="fullName" name="fullName" value={formData.fullName} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="birthday">Birthday (MM/DD/YYYY)</label>
					<input className="themed-input" id="birthday" name="birthday" value={formData.birthday} onChange={handleInputChange} type="date" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="gender">Gender</label>
					<select className="themed-input cursor-pointer" id="gender" name="gender" value={formData.gender} onChange={handleInputChange} required>
						<option value="">--Please choose an option--</option>
						<option value="male">Male</option>
						<option value="female">Female</option>
					</select>
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="driversLicense">Driver's License Number</label>
					<input className="themed-input" id="driversLicense" name="driversLicense" value={formData.driversLicense} onChange={handleInputChange} type="text" required />
				</div>
			</div>

			<p className="mt-8 text-[18px] font-bold underline text-themeColor cursor-pointer">Add more drivers?</p>
		</div>

		<div className='mt-8'>
			<p className='text-[22px] font-bold mb-4'>Contact Information</p>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="streetAddress">Street Address</label>
					<input className="themed-input" id="streetAddress" name="streetAddress" value={formData.streetAddress} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="email">Email</label>
					<input className="themed-input" id="email" name="email" value={formData.email} onChange={handleInputChange} type="email" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="city">City</label>
					<input className="themed-input" id="city" name="city" value={formData.city} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="phone">Phone</label>
					<input className="themed-input" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="state">State</label>
					<input className="themed-input" id="state" name="state" value={formData.state} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="reasonForShopping">Reason for shopping</label>
					<select className="themed-input cursor-pointer" id="reasonForShopping" name="reasonForShopping" value={formData.reasonForShopping} onChange={handleInputChange} required>
						<option value="">—Please choose an option—</option>
						<option value="Not insured">Not insured</option>
						<option value="Currently paying too much">Currently paying too much</option>
						<option value="Insured with bad company">Insured with bad company</option>
						<option value="Other">Other</option>
					</select>
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="zip">Zip</label>
					<input className="themed-input" id="zip" name="zip" value={formData.zip} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="comments">Comments (optional)</label>
					<input className="themed-input" id="comments" name="comments" value={formData.comments} onChange={handleInputChange} type="text" required />
				</div>
			</div>

			<button className='mt-4 bg-red-600 py-3 px-6 text-lg text-white rounded-lg shadow-inner'>Get Quote</button>
		</div>
	</form>
);
};

export default AutoInsurance