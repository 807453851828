import React from 'react'

const Reviews = () => {

	const reviews = [
    { 
        name: "Mari Ferreyra",
        rating: 5,
        content: "Andy is great to work with. Super friendly and explains everything very thoroughly. We got our home and car insurance with them and are currently working on adding pet insurance as well. Highly recommend!"
    },
    {
        name: "Joseph Scott",
        rating: 5,
        content: "Wow! Andy at Farmers came through again!! We received an email today that our business 'Workman’s Comp & Errors and ...'"
    },
    {
        name: "Elisa Pardo",
        rating: 5,
        content: "Navigating home insurance is not always easy but Andy has been incredibly supportive. He is very knowledgeable and responsive. Andy has stayed on top of timelines and needs sending us reminders and ongoing information to assure we are covered."
    },
    {
        name: "Cidalia Davis",
        rating: 5,
        content: "My husband and I have been with Farmers Insurance for 35 years. They have always been there for us when we have needed them. Andy Cortez became our agent when we moved to the area. He always takes the time to answer any questions that we may have. He works hard to find the best way to help us with our insurance needs."
    },
    {
        name: "Katrina e",
        rating: 5,
        content: "Thankful for Marylinn and Andy! On the market looking for home insurance coverage, and so glad I found this particular Farmer's office branch! Marylinn and Andy have been helpful every step of the way in assisting me secure home ..."
    },
    {
        name: "Alicia Valdiviezo",
        rating: 5,
        content: "Andy provided exemplary customer service. I felt at ease as he answered my questions about my life insurance options as well as the steps involved. I give Andy my highest recommendation because he demonstrated his knowledge of the services this agency provides."
    },
    {
        name: "Jessica Garcia",
        rating: 5,
        content: "Just got a new home & auto policy bundle with Andy and he KILLED IT! I was dropped unexpectedly by another local agent but Andy went above and beyond to create a custom policy for my needs! ..."
    },
    {
        name: "Micaela Buchanan",
        rating: 5,
        content: "Andy is the best! He worked hard to get us coverage that was difficult to find. He is quick to respond, honest and attentive. A gem in the insurance world!"
    },
    {
        name: "Graham's gone again",
        rating: 5,
        content: "Andy was very helpful and gave us an honest and truthful recommendation. He was supportive and friendly about our insurance challenges in California. We recommend him 100%!"
    },
    {
        name: "Richard Decola",
        rating: 5,
        content: "Hello people I'm not sure who you use for your policies but if I were you I would go see Andy. He is right on top of it getting things done and always available to help in any way. Need something done it's done. Not 2 days later but like right now. Best experience I've had give it a shot."
    },
    {
        name: "Nica Leal",
        rating: 5,
        content: "Marylinn was the Person In the Office who assisted me from beginning of My Enrollment to getting Car Insurance With Farmers. She is Great and was so helpful with getting me a policy that worked for me."
    },
    {
        name: "Jaslem Herrera",
        rating: 5,
        content: "We’ve received excellent service from Andy in providing us with our cars and renters insurance. He has been very professional and friendly. We’re very happy with the attention and help we received from him, especially with some very last"
    },
    {
        name: "Pop's Plumbing, Inc.",
        rating: 5,
        content: "Highly recommend Andy and his insurance team. We decided to switch insurance carriers after having some issues with our previous insurance provider. In our line of business, proper insurance coverage is essential and any lapse could be"
    },
    {
        name: "Daisy Ferreyra",
        rating: 5,
        content: "About 6 months ago we decided to switch our vehicles & business policies with Andy Cortez. Best decision made! Andy and his team have provided us with phenomenal service since day they are fast & efficient towards our needs."
    },
    {
        name: "Sarah Eastom",
        rating: 5,
        content: "WOW! For lack of better words.. We are incredibly grateful for the service and help provided by Framers, and ..."
    },
    {
        name: "Jamie Llamas",
        rating: 5,
        content: "Very pleasant experience, all the staff was very helpful. Will not hesitate to refer to this office."
    },
    {
        name: "Thomas Fontes",
        rating: 5,
        content: "Andy and his organization are great! Really appreciate being able to reach out and talk to someone instead of using online agencies that just ignore me or send me to a chatbot!"
    },
    {
        name: "Brian Williams",
        rating: 5,
        content: "Timely and Better rates than my previous insurance company!!"
    },
    {
        name: "Noemi Lopez",
        rating: 5,
        content: "Alex and Ivan ! 🤩 You are 👏 amazing! Thank you for all your help! You guys made me feel great after reviewing and understanding everything I need to know about homeowners insurance!"
    },
    {
        name: "Hector Gonzalez",
        rating: 5,
        content: "I recommend Farmers auto insurance. Ivan has been really helpful with my insurance process trying to get me the right auto policy. 👍🏼👍🏼"
    },
    {
        name: "Isaac Berumen",
        rating: 5,
        content: "Great customer service."
    }
  ]

	return (
		<div className="mt-[50px] max-w-4xl mx-auto px-4 py-5 space-y-4">
            <p className='text-[28px] md:text-[32px] lg:text-[32px] font-bold text-themeColor underline text-center mb-[28px]'>What Our Clients Have To Say</p>
			{reviews.map((review, index) => (
				<div key={index} className="p-4 bg-white shadow-lg rounded-md">
						<span className="block font-semibold text-lg text-gray-900">{review.name}</span>
						<span className="block text-yellow-400 text-lg">
								<p className="flex gap-2">{"★".repeat(review.rating)}{"☆".repeat(5 - review.rating)}</p>
						</span>
						<span className="block text-gray-600 mt-2">{review.content}</span>
				</div>
			))}

			<p className='text-2xl font-semibold'>and more ...</p>
		</div>
	)
}

export default Reviews