import React, { useState, useEffect, useRef } from 'react';
import Logo from '../assets/logo.png';
import { FaPhoneFlip as FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Header = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);  // Ref for the dropdown
    const toggleRef = useRef(null);    // Ref for the toggle

    const toggleDropdown = () => setShowDropdown(prev => !prev);
    const closeDropdown = () => setShowDropdown(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                toggleRef.current && !toggleRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Function to handle hover events
    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    return (
        <div className='flex flex-col items-center w-full bg-themeBg'>
            <div className='w-full h-[6px] bg-themeColor'/>
            <div className='py-6 w-full sm:w-[60%] flex flex-col sm:flex-row justify-between items-center'>
                <a href="/" className='h-[70px]'>
                    <img src={Logo} alt="logo" className='h-full hover:scale-[0.95] active:scale-[1.05] transition-transform cursor-pointer' />
                </a>
                <div className='flex flex-col text-themeColor text-[22px] mt-5 md:mt-0 lg:mt-0'>
                    <a className='flex items-center gap-2 hover:font-semibold transition-all group' href="tel:+18059341300">
                        <FaPhone className='mr-[3px] group-hover:p-[1px] transition-transform' />
                        (805) 934-1300
                    </a>
                    <a className='mt-2 flex items-center gap-2 hover:font-semibold transition-all group' href="mailto:hello@aciai.co">
                        <MdEmail size={28} className='mr-[2px] group-hover:p-[1px] transition-transform' />
                        hello@aciai.co
                    </a>
                </div>
                
            </div>
            <div className='w-full bg-themeColor text-white select-none'>
                <div className='ml-auto mr-auto w-full sm:w-[60%] flex flex-col md:flex-row lg:flex-row flex-wrap justify-center gap-2 font-medium text-center'>
                    <div 
                        className='nav-item sm:relative' 
                        ref={toggleRef} 
                        onClick={toggleDropdown} 
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        tabIndex={0}
                    >
                        Insurance
                        {showDropdown && (
                            <div ref={dropdownRef} className='mt-8 sm:mt-0 w-full sm:w-full sm:absolute sm:top-full top-[270px] left-0 absolute bg-white text-black shadow-lg z-[999]'>
                                <a href='/auto-insurance' className='nav-drop-down-item'>Auto Insurance</a>
                                <a href='/homeowners-insurance' className='nav-drop-down-item'>Homeowners Insurance</a>
                                <a href='/life-insurance' className='nav-drop-down-item'>Life Insurance</a>
                                <a href='/commercial-insurance' className='nav-drop-down-item'>Commercial Insurance</a>
                            </div>
                        )}
                    </div>
                    <a className='nav-item' href='/about'>About Us</a>
                    <a className='nav-item' href='/contact'>Contact Us</a>
                    <a className='nav-item' href='/careers'>Careers</a>
                </div>
            </div>
        </div>
    );
}

export default Header;
