import React, { useState } from 'react'

const Careers = () => {

return (
	<div className="flex flex-col">
		<div className="px-[80px] flex flex-col items-start w-full mx-auto p-6 rounded-lg">
			<h1 className="text-xl font-bold text-gray-800">Join Our Dynamic Insurance Agency</h1>
			<p className="mt-2 text-gray-600">
					We're seeking driven individuals eager to thrive in the insurance sector. Here's what awaits you:
			</p>
			<ul className="list-disc pl-5 mt-3 text-gray-600">
					<li>Lucrative career potential</li>
					<li>Supportive team environment</li>
					<li>Ongoing training and mentorship</li>
			</ul>
			<p className="mt-4 text-gray-600">
					No experience? No problem! Here's what you'll be doing:
			</p>
			<ul className="list-disc pl-5 mt-3 text-gray-600">
					<li>Identifying new business prospects and selling policies to meet performance goals</li>
					<li>Actively marketing financial and insurance services to attract potential clients</li>
					<li>Conducting Farmers Friendly Review appointments to ensure clients have sufficient coverage</li>
					<li>Addressing policy service issues and communicating with clients as needed</li>
					<li>Initiating cross-selling opportunities during client interactions</li>
					<li>Maintaining organized electronic files for customer accounts</li>
					<li>Assisting with insurance claims filing</li>
					<li>Providing professional and courteous responses to incoming calls</li>
			</ul>
			<div className="mt-4">
					<h2 className="text-lg font-semibold text-gray-800">Qualifications and Education Requirements:</h2>
					<ul className="list-disc pl-5 mt-2 text-gray-600">
							<li>California Property & Casualty and Life Licenses required</li>
							<li>High School diploma or equivalent required</li>
					</ul>
			</div>
			<div className="mt-4">
					<h2 className="text-lg font-semibold text-gray-800">Competencies and Skills:</h2>
					<ul className="list-disc pl-5 mt-2 text-gray-600">
							<li>Demonstrated success in sales and marketing</li>
							<li>Strong customer service skills</li>
							<li>Excellent written and verbal communication abilities</li>
							<li>Effective problem-solving skills and ability to work well in a team</li>
							<li>Openness to feedback and attention to detail</li>
							<li>Proficiency in Microsoft Word and Excel, with a willingness to learn new technologies</li>
					</ul>
			</div>
			<div className="mt-4">
					<h2 className="text-lg font-semibold text-gray-800">All you need is:</h2>
					<ul className="list-disc pl-5 mt-2 text-gray-600">
							<li>A positive mindset</li>
							<li>Strong work ethic</li>
							<li>Proficiency in Spanish</li>
							<li>Availability to work Monday to Friday</li>
					</ul>
			</div>
			<a className='mt-8 bt-4 px-4 py-3 font-bold bg-themeColor text-white rounded-md' href='mailto:acortez3@farmersagent.com'>Apply Now</a>
		</div>
	</div>
);
};

export default Careers