import React from 'react';
import { FaFacebookF, FaInstagram, FaGoogle, FaYelp, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="mt-auto bg-themeColor text-white py-[20px]">
      <div className="container mx-auto px-6 py-4">
        <div className="flex flex-col md:flex-row lg:flex-row justify-between items-center">
          <div>
            <p className="mt-4 text-lg w-fit text-white rounded mb-8">
                <b>Agency License #:</b> <span class="underline">6011975</span>
            </p>
            <div className="flex justify-start items-center flex-wrap gap-2 md:gap-0 lg:gap-0">
              <a href="https://www.facebook.com/profile.php?id=100089502927847&mibextid=ZbWKwL" className="bg-white p-4 rounded-full text-[#0266FF] text-xl mr-6"><FaFacebookF /></a>
              <a href="https://www.instagram.com/andycortezinsuranceagency" className="bg-white p-4 rounded-full text-[#fafafa] text-xl mr-6" style={{ backgroundImage: `url(${require('../assets/instagram.png')})`, backgroundPosition: `center`, backgroundSize: `cover`}}><FaInstagram /></a>
              <a href="https://www.linkedin.com/in/andy-cortez-mba-66618784?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className="bg-[#0077B5] p-4 rounded-full text-[#fafafa] text-xl mr-6"><FaLinkedin /></a>
              <a href="https://g.co/kgs/n2W44XW" className="bg-white p-4 rounded-full text-[#fafafa] text-xl mr-6" style={{ backgroundImage: `url(${require('../assets/google.png')})`, backgroundPosition: `center`, backgroundSize: `cover`}}><FaGoogle /></a>
              <a href="https://agents.farmers.com/ca/santa-maria/andy-cortez/auto-insurance/" className="bg-[#DF1731] p-4 rounded-full text-[#fafafa] text-xl mr-6 w-[50px] h-[50px]"><img className='scale-[1.5] pt-[2px]' src={require('../assets/farmers.png')} /></a>
              <a href="https://m.yelp.com/biz/andy-cortez-agency-famers-insurance-santa-maria" className="bg-[#FF1A1A] p-4 rounded-full text-[#fafafa] text-xl mr-6"><FaYelp /></a>
            </div>
          </div>
          <div className="text-left py-8">
            <h3 className="text-lg font-bold mb-2 w-full">Contact Us</h3>
            <p>Phone: <a className="font-bold" href="tel:+18059341300">(805) 934-1300</a></p>
            <p>Fax: <a className="font-bold" href="tel:+18059341339">(805) 934-1339</a></p>
            <p className='my-2'>Email Us: <a className="font-bold" href="mailto:support@aciai.co">support@aciai.co</a></p>
            <p>
              2646 Santa Maria Way Ste 105<br />
              Santa Maria, CA 93455
            </p>
            <p className='my-2'>Monday to Friday 8:30am to 5:00pm</p>
          </div>
        </div>
        <div className="text-center mt-4">
          <a href="#top" className="text-sm hover:underline">Return to top of page</a>
        </div>
      </div>
      <div className="text-center py-4 border-t border-gray-700">
        <p>Copyright © {new Date().getFullYear()} · ACIAI Inc.</p>
      </div>
    </footer>
  );
};

export default Footer;
