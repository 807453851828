import axios from 'axios';
import React, { useState } from 'react'
import { useLocation } from 'wouter';

const LifeInsurance = () => {
	
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
  });

  // Handler for input changes to update formData state
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

	const [location, navigate] = useLocation()


	const submitForm = async () => {
		const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/45866956/66d73e3a-68a7-43ea-9b84-f927eb3ea092`
	 
			 var name = formData.fullName.split(' ')
			 const firstName = name[0] || ''
			 const lastName = name[1] || ''
			 
			 const hubspotFormData = {
				fields: [
					{
						name: "email",
						value: formData.email,
					},
					{
						name: "firstname",
						value: firstName,
					},
					{
						name: "lastname",
						value: lastName,
					},
					{
						name: "phone",
						value: formData.phone,
					},
				]
			 }
	 
			 try {
				 await axios.post(HUBSPOT_ENDPOINT, hubspotFormData)
				 .then(async() => {
					//  setLoading(false)
	 
					 navigate('/')
				 })
			 } catch (error) {
					 console.error('Network error:', error)
					//  setLoading(false)
			 }
		 }

  // Handler for form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    submitForm()
  };


return (
	<form onSubmit={handleSubmit} className="p-8 rounded-md shadow-sm max-w-[80%] w-[80%] mx-auto my-10 border border-gray-100">
		<div className='py-[40px] px-[20px] border-t-[1px] border-b-[1px] border-dashed border-themeColor'>
			<p className='text-[32px] font-bold text-themeColor'>Life Insurance</p>
			<p className='text-[22px] font-medium text-gray-500'>Get your Free Life Insurance Quote Today!</p>
		</div>

		<div className='mt-8'>
			<div className="flex flex-col gap-4 mb-4">
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="zip">Full Name</label>
					<input className="themed-input" id="fullName" name="fullName" value={formData.fullName} onChange={handleInputChange} type="text" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="email">Email</label>
					<input className="themed-input" id="email" name="email" value={formData.email} onChange={handleInputChange} type="email" required />
				</div>
				<div className='input-field'>
					<label className="block mb-2 font-semibold" htmlFor="phone">Phone</label>
					<input className="themed-input" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} type="text" required />
				</div>
			</div>

		</div>

		<button className='mt-4 bg-red-600 py-3 px-6 text-lg text-white rounded-lg shadow-inner'>Get Quote</button>
	</form>
);
};

export default LifeInsurance