import { Route, Switch } from "wouter";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import DetailsSection from "./components/DetailsSection";
import WhyChooseUs from "./components/WhyChooseUs";
import SecureYourFuture from "./components/SecureYourFuture";
import CostEffectiveProtection from "./components/CostEffectiveProtection";
import TailoredSolutions from "./components/TailoredSolutions";
import Footer from "./components/Footer";
import AutoInsurance from "./pages/AutoInsurance";
import HomeOwnersInsurance from "./pages/HomeOwnersInsurance";
import LifeInsurance from "./pages/LifeInsurance";
import CommercialInsurance from "./pages/CommercialInsurance";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Reviews from "./components/Reviews";
import Careers from "./pages/Careers";

const App = () => {
  return (
    <div className='flex flex-col min-h-screen w-full bg-themeBg'>
      <Header />
      <Switch>
        <Route path='/'>
          <HeroSection />
          <DetailsSection />
          <WhyChooseUs />
          <SecureYourFuture />
          <CostEffectiveProtection />
          <TailoredSolutions />
          <Reviews />
        </Route>

        <Route path='/auto-insurance' component={AutoInsurance} />
        <Route path='/homeowners-insurance' component={HomeOwnersInsurance} />
        <Route path='/life-insurance' component={LifeInsurance} />
        <Route path='/commercial-insurance' component={CommercialInsurance} />
        <Route path='/about' component={AboutUs} />
        <Route path='/contact' component={ContactUs} />
        <Route path='/careers' component={Careers} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
